import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import $ from 'jquery';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { images } from "./utils/imagesToLoad";

import Main from './pages/Main';
import AboutUs from './pages/AboutUs';
import AccounTypes from "./pages/AccountTypes";
import FAQs from "./pages/FAQs";
import Product from "./pages/Products";
import Services from "./pages/Services";
import Calendar from "./pages/Calendar";
import Contacts from "./pages/Contacts";

import './App.scss';

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]);

  return (
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/:lang/" element={<Main />} />
            <Route path="/:lang/faqs" element={<FAQs />} />
            <Route path="/:lang/account-types" element={<AccounTypes />} />
            <Route path="/:lang/services" element={<Services />} />
            <Route path="/:lang/products" element={<Product />} />
            <Route path="/:lang/about" element={<AboutUs />} />
            <Route path="/:lang/contact" element={<Contacts />} />
            <Route path="/:lang/calendar" element={<Calendar />} />
            <Route path='/*' element={<Navigate to='/en/' replace />} />
          </Routes>
        </Router>

      </HelmetProvider>
  );
}

export default App;
