import React from 'react';
import { useTranslation } from "react-i18next";
import styles from './GridDoc.module.scss';
import iconPdf from '../../../assets/images/faqs/pdf.png';

function GridDoc() {
  const { t } = useTranslation();
  const list = t('faq-tabs-acc-content-4', { returnObjects: true });

  return (
    <div className={styles.wrap}>
      {list.map((item, index) => (
        <a href={`/docs/${item.content}`} className={styles.item} target='_blank' key={index}>
          <img src={iconPdf} alt='' className={styles.itemIcon} />
          <div>{t(`${item.title}`)}</div>
        </a>
      ))}
    </div>
  )
}

export default GridDoc;
