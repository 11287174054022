import React from 'react';
import { useTranslation } from "react-i18next";

import styles from './ContactBlock.module.scss';

function ContactBlock() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={styles.title}>{t('footer.central_office')}</div>
          <div className={styles.text}>Office 01, Level 9, Gate District, Gate Building, DIFC, PO Box 938542, Dubai, UAE</div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>{t('footer.hadquartered')}</div>
          <div className={styles.text}>119 Upper Richmond Road, London, England, SW15 6TR</div>
        </div>
        <div className={styles.item}>
          <div className={styles.title}>{t('footer.callus')}</div>
          <a href="tel:+971521045528" className={`${styles.text} ${styles.phone}`}>+971521045528</a>
        </div>
      </div>
    </div>
  )
}

export default ContactBlock;
