import React from 'react';
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import ContactBlock from '../components/ContactPage/ContactBlock';

function Contacts() {
  return (
    <div className='ovf-hidden contact-page'>
      <Header />
      <ContactBlock />
      <Footer />
    </div>
)
  
}

export default Contacts;
