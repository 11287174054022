import React, { useEffect } from 'react';
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';

import main from '../assets/images/2.webp';

import Fade from 'react-reveal/Fade'

import { useTranslation, Trans } from "react-i18next";

function Calendar () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const { t } = useTranslation();

    return(
        <div className='ovf-hidden calendar-page'>
            <Header />
            <div >
                <div className="relative overflow-hidden">
                    <div className="max-w-[1280px] mx-[20px] xl:mx-auto relative">
                        <div className="flex justify-center xl:justify-normal">
                            <div className="absolute">
                                <Fade delay={500}><p className="calendar-title main-title"><Trans>{t('calendar.1')}</Trans></p></Fade>
                                <Fade delay={700}><p className="calendar-text main-text">{t('calendar.2')}</p></Fade>
                            </div>
                        </div>
                    </div>
                    <img src={main} alt="" className="calendar-image"/>
                    <div className='absolute h-[100px] bg-white w-full bottom-0 rounded-t-[40px] xl:rounded-t-[100px]'></div>
                </div>
                <div className='calendar container'>
                    <iframe className='w-full h-[600px]' src="/Calendar.html" title='0' frameborder="0"></iframe>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Calendar;