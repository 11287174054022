import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/component';
import GridDoc from '../GridDoc/GridDoc';

import arrowblack from '../../../assets/images/arrow-right.svg';

import './style.scss';

const tabContent = [
  {
    id: 0,
    title: 'faq-0-tabs-acc-title-0',
  },
  {
    id: 1,
    title: 'faq-0-tabs-acc-title-1',
  },
  {
    id: 2,
    title: 'faq-0-tabs-acc-title-2',
  },
  {
    id: 3,
    title: 'faq-0-tabs-acc-title-3',
  },
  {
    id: 4,
    title: 'faq-0-tabs-acc-title-4',
  },
];

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const handleTabClick = (id) => {
    setActiveTab(id);
    setDropdownOpen(false);
  };

  const dropdownTabs = tabContent.filter(tab => tab.id !== activeTab);

  const data = t('faq-0-tabs-acc', { returnObjects: true });

  return (
    <div className='aboutus-tabs'>
      <div className="container">
      <div className="tab-dropdown">
        <button className='selected' onClick={() => setDropdownOpen(!dropdownOpen)}>
          <i className='icon'>
            <img src={arrowblack} alt='' />
          </i>
          {t(`${tabContent.find(tab => tab.id === activeTab).title}`)}
        </button>
        {dropdownOpen && (
          <div className="dropdown-options">
            {dropdownTabs.map(tab => (
              <button key={tab.id} onClick={() => handleTabClick(tab.id)}>
                <i className='icon'>
                  <img src={arrowblack} alt='' />
                </i>
                {t(`${tab.title}`)}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="tab-buttons">
        {tabContent.map(tab => (
          <button
            key={tab.id}
            className={tab.id === activeTab ? 'active' : ''}
            onClick={() => handleTabClick(tab.id)}
          >
            <i className='icon'>
              <img src={arrowblack} alt='' />
            </i>
            {t(`${tab.title}`)}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {tabContent.map((tab, index) => (
          <div className={`content-item ${tab.id === activeTab ? 'active' : ''}`}>
            <div className='content'>
              {tab.id == '4' ? <GridDoc /> : <Accordion title={`faq-tabs-acc-content-${index}`} />}
              
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default TabComponent;
